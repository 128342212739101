.marker-cluster-small {
	background-color: rgba(181, 226, 140, 0.6);
	}
.marker-cluster-small div {
	background-color: rgba(110, 204, 57, 0.6);
	}

.marker-cluster-medium {
	background-color: rgba(241, 211, 87, 0.6);
	}
.marker-cluster-medium div {
	background-color: rgba(240, 194, 12, 0.6);
	}

.marker-cluster-large {
	background-color: rgba(253, 156, 115, 0.6);
	}
.marker-cluster-large div {
	background-color: rgba(241, 128, 23, 0.6);
	}

	/* IE 6-8 fallback colors */
.leaflet-oldie .marker-cluster-small {
	background-color: rgb(181, 226, 140);
	}
.leaflet-oldie .marker-cluster-small div {
	background-color: rgb(110, 204, 57);
	}

.leaflet-oldie .marker-cluster-medium {
	background-color: rgb(241, 211, 87);
	}
.leaflet-oldie .marker-cluster-medium div {
	background-color: rgb(240, 194, 12);
	}

.leaflet-oldie .marker-cluster-large {
	background-color: rgb(253, 156, 115);
	}
.leaflet-oldie .marker-cluster-large div {
	background-color: rgb(241, 128, 23);
}


.marker-cluster-small {
	background-color: rgba(246, 167, 83, .6);
	}
.marker-cluster-small div {
	background-color: rgba(246, 167, 83, 1);
	}

.marker-cluster-medium {
	background-color: rgba(247, 179, 107, .6);
	}
.marker-cluster-medium div {
	background-color: rgba(247, 179, 107, 1);
	}

.marker-cluster-large {
	background-color: rgba(248, 191, 131, .6);
	}
.marker-cluster-large div {
	background-color: rgba(248, 191, 131, 1);
	}

	/* IE 6-8 fallback colors */
.leaflet-oldie .marker-cluster-small {
	background-color: rgb(253, 156, 115);
	}
.leaflet-oldie .marker-cluster-small div {
	background-color: rgb(241, 128, 23);
	}

.leaflet-oldie .marker-cluster-medium {
	background-color: rgb(253, 156, 115);
	}
.leaflet-oldie .marker-cluster-medium div {
	background-color: rgb(241, 128, 23);
	}

.leaflet-oldie .marker-cluster-large {
	background-color: rgb(253, 156, 115);
	}
.leaflet-oldie .marker-cluster-large div {
	background-color: rgb(241, 128, 23);
}

.marker-cluster {
	background-clip: padding-box;
	border-radius: 20px;
	}
.marker-cluster div {
	width: 30px;
	height: 30px;
	margin-left: 5px;
	margin-top: 5px;

	text-align: center;
	border-radius: 15px;
	font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
	}
.marker-cluster span {
	line-height: 30px;
	}